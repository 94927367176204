<template>
  <div class="wrap">
    <header class="header">
      <img class="header-title" src="/images/industry/title.png" alt="" />
    </header>
    <div class="d-f-c">
      <div class="content">
        <h3 class="content-title">小镇产业</h3>
<!--        <h3 class="content-subTitle">Small town industry</h3>-->
        <ul class="content-list">
          <li
            v-for="item in list"
            :key="item.id"
            class="list-item"
            @click="goPage(`/articledetail?id=${item.id}&pt=0${this.$route.query?.hasOwnProperty('tn')?`&tn=${this.$route.query?.tn}`:''}${this.$route.query?.hasOwnProperty('tid')?`&tid=${this.$route.query?.tid}`:''}`)"
          >
            <img class="item-cover" :src="item.picture" alt="" />
            <div class="item-content">
              <h3 class="item-title">{{ item.title }}</h3>
<!--              <p class="content-desc">-->
<!--                {{ item.content }}-->
<!--              </p>-->
              <h4 class="content-date">{{$date.moment( item.createDate,'YYYY-MM-DD') }}</h4>

            </div>
          </li>
        </ul>
        <NoData :visible="!list.length" />
        <div class="page">
          <Page
            class="page-tools"
            :size="params.pageSize"
            :current="pageNum"
            :total="total"
            @pageChange="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NoData from '@/components/common/nodata'
import { useRoute, onBeforeRouteUpdate } from 'vue-router'
import Page from '@/components/common/paginationwhite'
import { useList, useParams } from '@/hooks/useList'
import api from '@/api'
import { hideTextTag, formatDate } from '@/utils/tools'

export default {
  name: 'industry',
  components: {
    Page,
    NoData
  },
  setup () {
    const route = useRoute()
    const filter = list => {
      return list.map(item => ({
        ...item,
        content: hideTextTag(item.content),
        publishDate: formatDate(item.publishDate)
      }))
    }
    const { params, search, } = useParams({
      townId: route.query?.tid ?? null,
      pageNum: route.query?.pn ?? 1,
      pageSize: 8,
      tabs:'INDUSTRY',
    })
    const { total, pageNum, list, getList } = useList(params, api.townProduct, filter)
    onBeforeRouteUpdate((to) => {
      search({
        pageNum: to.query?.pn,
        townId: to.query?.id
      })
    })

    return {
      total,
      pageNum,
      params,
      list,
      getList,
      search
    }

  },
  methods: {
    goPage (url) {
      this.$router.push(url)
    },
    pageChange (page) {
      this.$router.push({
        query: {
          pn: page
        }
      })
    }
  }

}
</script>
<style lang="scss" scoped>
.wrap {
  min-height: calc(100vh - 1.06rem);
  //background: url("/images/common/bg.png");
  background: #F4F4F4;
  .header {
    height: 7.36rem;
    width: 100%;
    background: url("/images/industry/banner.png") no-repeat;
    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .header-title {
      width: 7.17rem;
    }
  }
  .content {
    width: 12rem;
    padding: 0.6rem 0;
    min-height: calc(100vh - 5.6rem);
    .content-title {
      font-size: 0.3rem;
      font-weight: bold;
      color: #333333;
    }
    .content-subTitle {
      font-size: 0.14rem;
      font-weight: normal;
      color: #999999;
    }
    .content-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      margin-top: 0.35rem;

      .list-item {
        position: relative;
        width: 2.88rem;
        height: 3.72rem;
        background: #fff;
        cursor: pointer;
        margin-bottom: 0.23rem;

        transition: all 300ms;
        &:not(:nth-of-type(4n)) {
          margin-right: 0.16rem;
        }

        .item-title {
          font-size: 0.16rem;
          font-weight: 400;
          @include text-moreLine(1);
        }

        //&:hover {
        //  background: #b82b2b;
        //  transition: all 300ms;
        //  cursor: pointer;
        //  .item-content {
        //
        //  }
        //}
        .item-border {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 5.12rem;
        }
        .item-cover {
          display: block;
          width: 100%;
          height: 2.88rem;

          object-fit: cover;
        }
        .item-content {
          padding: 0.14rem 0.16rem 0 0.16rem;
          height: 1.8rem;
          position: relative;
          .content-title {
            font-size: 0.16rem;
            font-weight: normal;
            color: #333333;
            @extend .text-oneLine;
          }
          .content-date {
            font-size: 0.12rem;
            color: #999999;
            font-weight: normal;
            margin-top: 0.1rem;
          }
          .content-desc {
            font-size: 0.14rem;
            margin-top: 0.06rem;
            color: #999999;
            @include text-moreLine(1);
          }
        }
      }
    }
  }
  .page {
    display: flex;
    justify-content: center;
    margin-top: 0.8rem;
  }
}
</style>
